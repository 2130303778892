<template>
  <div class="app-container service-guide-comp">
    <div class="filter-line">
      <span>
        <el-button type="primary" @click="addNewHandler">{{
          this.$t("commons.addNew")
        }}</el-button>
        <el-tooltip class="item" effect="dark" :disabled="guideObj.selectionList.length > 0"
          :content="$t('commons.multipleSelectionOne')" placement="top">
          <el-button type="danger" @click="batchDeleteHandler"
            :class="guideObj.selectionList.length === 0 && 'not-allowed-button'" icon="el-icon-delete" plain>{{
          $t("commons.delete") }}
          </el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" :disabled="guideObj.selectionList.length > 0"
          :content="$t('commons.multipleSelectionOne')" placement="top">
          <el-button type="primary" :class="guideObj.selectionList.length === 0 &&
          'not-allowed-primary-button'
          " @click="batchUpdate(0)">{{ this.$t("commons.enable") }}</el-button>
        </el-tooltip>
        <el-tooltip class="item" effect="dark" :disabled="guideObj.selectionList.length > 0"
          :content="$t('commons.multipleSelectionOne')" placement="top">
          <el-button type="primary" :class="guideObj.selectionList.length === 0 &&
          'not-allowed-primary-button'
          " @click="batchUpdate(1)">{{ this.$t("commons.disabled") }}</el-button>
        </el-tooltip>
      </span>
      <div class="header-filter-button" @click="dataset.filterLine.show = !dataset.filterLine.show" :class="dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
          ">
        <i class="iconfont icon-loudoutu"></i>
        筛选
      </div>
    </div>
    <div>
      <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler">
        <template v-slot:projectIdSlot="row">
          <span> {{ row.datas.projectName }}</span>
        </template>
      </finalTable>
    </div>

    <!-- 新增dialog -->
    <el-dialog :visible.sync="guideObj.dialogVisible" :before-close="handleClose" width="728px" :destroy-on-close="true"
      :close-on-click-modal="false">
      <template slot="title">
        <span>{{ guideObj.dialogTitle }}</span>
      </template>
      <guideAddDialog :businessTypeList="businessTypeList" :guideObj="guideObj" @close="handleClose" />
    </el-dialog>

    <!-- 下载记录dialog -->
    <el-dialog :visible.sync="guideObj.historyDialogVisible" :before-close="historyHandleClose" width="1000px"
      :destroy-on-close="true" :close-on-click-modal="false">
      <template slot="title">
        <span>{{ guideObj.dialogTitle }}</span>
      </template>
      <templateHistoryDialog v-if="guideObj.historyDialogVisible" :id="guideObj.id" />
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import { findLookupItem } from "@/api/ruge/lookup/lookup";
import guideAddDialog from "./guideAddDialog";
import templateHistoryDialog from "./templateHistory";
import { envInfo } from "@/constants/envInfo";
// import {
//   getServiceGuideList,
//   updateServiceGuideState,
//   deleteServiceGuide,
//   downloadServiceGuide,
// } from "@/api/ruge/gsPark/customerService/serviceGuide";

import {
  getServiceGuideList,
  updateServiceGuideState,
  deleteServiceGuide,
  downloadServiceGuide,
} from "@/api/ruge/lego/informationDelivery/serviceGuide"
import { getProjectList } from "@/api/business/base/tenant/map";

export default {
  components: {
    guideAddDialog,
    finalTable,
    templateHistoryDialog,
  },
  data() {
    return {
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: true,
          prop: "productId",
          width: "18",
        },
        header: [
          { prop: "projectId", label: "项目名称", width: "" },
          { prop: "fileName", label: "文件名称", width: "" },
          { prop: "businessType", label: "业务类型", width: "" },
          { prop: "fileFormat", label: "文件格式", width: "" },
          { prop: "downloadNum", label: "申请次数", width: "" },
          { prop: "createTime", label: "上传时间", width: "" },
          { prop: "createBy", label: "上传人", width: "" },
          { prop: "status", label: "启用状态", width: "" },
          { prop: "operation", label: "操作", width: "190" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          projectId: {
            type: "selector",
            label: "项目名称",
            value: "",
            placeholder: "请选择项目名称",
            actionType: "goSearch",
            optionList: [],
          },
          businessType: {
            type: "selector",
            label: "业务类型",
            value: "",
            placeholder: "请选择业务类型",
            actionType: "goSearch",
            optionList: [],
          },
          fileFormat: {
            type: "selector",
            label: "文件格式",
            value: "",
            actionType: "goSearch",
            placeholder: "请选择文件格式",
            optionList: [
              {
                label: "PDF",
                value: "pdf",
              },
              {
                label: "Word",
                value: "doc",
              },
              {
                label: "Excel",
                value: "xls",
              },
              {
                label: "PPT",
                value: "ppt",
              },
            ],
          },
          status: {
            type: "selector",
            label: "启用状态",
            value: "",
            actionType: "goSearch",
            placeholder: "请选择启用状态",
            optionList: [
              {
                label: "启用",
                value: "0",
              },
              {
                label: "禁用",
                value: "1",
              },
            ],
          },
          createBy: {
            type: "input",
            label: "上传人",
            value: "",
            actionType: "goSearch",
            placeholder: "请输上传人",
            prefixIcon: "el-icon-search",
          },
          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          projectId: {
            type: 'slot',
            slotName: 'projectIdSlot'
          },
          businessType: {
            type: "enumerationColumn",
            emuList: {},
          },
          createTime: {
            type: "dateFormat",
          },
          status: {
            type: "enumerationColumn",
            emuList: {
              0: "启用",
              1: "禁用",
            },
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "start",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-video-play",
                tooltips: "启用",
                hideProp: "status",
                hideValue: 0,
              },
              {
                actionType: "iconClick",
                eventName: "stop",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-video-pause",
                tooltips: "禁用",
                hideProp: "status",
                hideValue: 1,
              },
              {
                actionType: "iconClick",
                eventName: "download",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-download",
                tooltips: "下载",
              },

              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "下载记录",
              },
              {
                actionType: "iconClick",
                eventName: "delete",
                fontSize: "14px",
                color: "#E47470",
                cursorPointer: "pointer",
                iconName: "el-icon-delete",
                tooltips: "删除",
              },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          businessType: {
            inline: true,
            value: "",
          },
          fileFormat: {
            inline: true,
            value: "",
          },
          status: {
            inline: true,
            value: "",
          },
          createBy: {
            inline: true,
            value: "",
          },
          createTime: {
            type: "dateRange",
            label: "创建时间",
            value: [],
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      guideObj: {
        selectionList: [],
        dialogVisible: false,
        historyDialogVisible: false,
        dialogTitle: "新增服务模板",
        id: null,
        listQuery: {
          current: 1,
          rowCount: 10,
          businessType: null,
          fileFormat: null,
          createBy: null,
          status: null,
          startTime: null,
          endTime: null,
        },
      },
      businessTypeList: [],
      businessType: {},
    };
  },
  created() {
    this.dataInit();
    this.getProjectList()
  },
  methods: {
    getTableList() {
      const params = { ...this.guideObj.listQuery };
      getServiceGuideList(params).then((res) => {
        console.log("res", res);
        this.dataset.pageVO.total = res.data.total;
        this.dataset.tableData = res.data.rows;
      });
    },
    getProjectList() {
      getProjectList().then(res => {
        let compantList = []
        res.forEach(item => {
          compantList.push({
            label: item.projectName,
            value: item.projectId
          })
        })
        this.dataset.searchLineConfig.projectId.optionList = compantList
        this.guideObj.projectList = compantList
      })
    },
    historyHandleClose() {
      this.guideObj.historyDialogVisible = false;
    },
    handleClose(freshFlag) {
      this.guideObj.dialogVisible = false;
      freshFlag && this.getTableList();
    },
    addNewHandler() {
      this.guideObj.dialogTitle = "新增服务模板";
      this.guideObj.dialogVisible = true;
    },
    dataInit() {
      this.getTableList();
      findLookupItem("CUST_YEWU_TYPE").then((row) => {
        let businessType = {};
        this.dataset.searchLineConfig.businessType.optionList = row.map(
          (item) => {
            businessType[item.itemCode] = item.itemName;
            this.businessTypeList.push({
              value: item.itemCode,
              label: item.itemName,
            });
            return {
              value: item.itemCode,
              label: item.itemName,
            };
          }
        );
        this.businessType = businessType;
        this.dataset.detailConfig.businessType.emuList = businessType;
      });
    },
    batchUpdate(status) {
      if (this.guideObj.selectionList.length === 0) return;
      this.updateState(this.guideObj.selectionList, status);
    },
    updateState(datas, status) {
      const params = datas.map((item) => {
        return {
          id: item.id,
          status: status,
        };
      });
      updateServiceGuideState(params).then((res) => {
        if (res.code === 200) {
          this.$message.success("状态更新成功！");
          this.getTableList();
        } else {
          this.$message.warning("状态更新失败！");
        }
      });
    },
    batchDeleteHandler() {
      if (this.guideObj.selectionList.length === 0) return;
      // this.deleteHandler(this.guideObj.selectionList);
      console.log(123);
      let confrimList = [];
      let confirmText = "删除后将不可恢复，确定删除么？";
      this.guideObj.selectionList.forEach((item) => {
        if (item.typeCount === 1) {
          confrimList.push(
            `文件："${item.fileName}" 为类型："${this.businessType[item.businessType]
            }" 下唯一的服务模板，`
          );
        }
      });
      if (confrimList.length > 0) {
        confirmText =
          confrimList.join(" ") +
          "删除后用户申请将会收到空邮件，并且删除后将不可恢复，确定删除吗？";
      }
      console.log(111);
      this.deleteHandler(
        this.guideObj.selectionList.map((item) => item.id),
        confirmText
      );
    },
    singleDeleteHandler(row) {
      let confirmText = "删除后将不可恢复，确定删除么？";
      if (row.typeCount === 1) {
        confirmText = `文件："${row.fileName}" 为类型："${this.businessType[row.businessType]
          }" 下唯一的服务模板，删除后用户申请将会收到空邮件，并且删除后将不可恢复，确定删除吗？`;
      }
      this.deleteHandler([row.id], confirmText);
    },
    deleteHandler(ids, confirmText) {
      this.$confirm(
        confirmText ? confirmText : "删除后将不可恢复，确定删除么？",
        this.$t("commons.warning"),
        {
          confirmButtonText: this.$t("commons.confirm"),
          cancelButtonText: this.$t("commons.cancel"),
          type: "warning",
        }
      )
        .then(() => {
          deleteServiceGuide(ids).then((res) => {
            if (res.code === 200) {
              this.$message.success("删除成功！");
              this.getTableList();
            } else {
              this.$message.warning("删除失败！");
            }
          });
        })
        .catch((error) => {
          console.log(`未删除，原因 => ${error}`);
        });
    },
    downloadHandler({ id, fileId }) {
      //下载文件
      const url =
        envInfo.bgApp.archivePath +
        "/param/archive/download?dlType=DefaultDownload&fi=" +
        fileId;
      const asemName = "txt";
      const element = document.createElement("a");
      element.setAttribute("href", url);
      element.setAttribute("download", asemName);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);

      downloadServiceGuide({ id }).then(() => {
        this.getTableList();
      });
    },
    tableEventHandler(datas) {
      if (datas.type === "goSearch") {
        if (datas.params.createTime && datas.params.createTime.length > 0) {
          this.guideObj.listQuery.startTime = datas.params.createTime[0]
            ? new Date(datas.params.createTime[0]).getTime()
            : null;
          this.guideObj.listQuery.endTime = datas.params.createTime[1]
            ? new Date(datas.params.createTime[1]).getTime()
            : null;
          delete datas.params.createTime;
        }
        //  else {
        //   this.guideObj.listQuery.endTime = null;
        //   this.guideObj.listQuery.startTime = null;
        // }
        this.guideObj.listQuery = {
          ...this.guideObj.listQuery,
          ...datas.params,
        };
        this.guideObj.listQuery.current = 1;
        this.getTableList();
      } else if (datas.type === "paginationChange") {
        this.guideObj.listQuery.current = datas.params.current.page;
        this.guideObj.listQuery.rowCount = datas.params.current.limit;
        this.getTableList();
      } else if (datas.type === "updateSelectionList") {
        this.guideObj.selectionList = datas.list.filter((item) => item);
      } else if (datas.type === "switchEvent") {
        // this.updateDynamicRegistration(datas.row);
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "start":
            this.updateState([datas.row], 0);
            break;
          case "stop":
            this.$confirm(
              "禁用后用户申请将无法获取该模板，确定禁用吗",
              this.$t("commons.warning"),
              {
                confirmButtonText: this.$t("commons.confirm"),
                cancelButtonText: this.$t("commons.cancel"),
                type: "warning",
              }
            )
              .then(() => {
                this.updateState([datas.row], 1);
              })
              .catch((error) => {
                console.log(`未删除，原因 => ${error}`);
              });

            break;
          case "view":
            console.log("datas.row", datas.row);
            this.guideObj.dialogTitle =
              this.businessType[datas.row.businessType] +
              datas.row.fileName +
              "模板历史下载记录";
            this.guideObj.id = datas.row.id;
            this.guideObj.historyDialogVisible = true;
            break;
          case "delete":
            this.singleDeleteHandler(datas.row);
            break;
          case "download":
            this.downloadHandler(datas.row);
            break;
        }
      }
    },
  },
};
</script>

<style scoped lang="less">
.service-guide-comp {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    button {
      height: 36px;
    }

    .icon-loudoutu {
      font-size: 12px;
    }

    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }

    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }

    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }

    .not-allowed-button {
      cursor: not-allowed;
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f9a7a7;
    }

    .not-allowed-primary-button {
      cursor: not-allowed;
      color: #fff;
      background-color: #a0cfff;
      border-color: #a0cfff;
    }
  }
}
</style>